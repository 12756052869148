/* eslint-disable no-unused-vars */
import lightGallery from 'lightgallery';
import lightSlider from 'lightslider';

// Styles
import resetCss from 'src/styles/components/_reset.sass';
import normalizeCss from 'normalize.css';
import lightGalleryCss from 'lightgallery/dist/css/lightgallery.min.css';
import lightSliderCss from 'lightslider/dist/css/lightslider.min.css';
import styles from 'src/styles/main.sass';
import mediaStyles from 'src/styles/media.sass';


const mainNav = () => {
  const nav = document.querySelector('.my-nav');
  const $navTrigger = $('.nav__mob-button, .nav__overlay');

  function navToggle() {
    // hide
    if (nav.classList.contains('visible')) {
      document.documentElement.classList.remove('modal-open');

      // open
    } else {
      document.documentElement.classList.add('modal-open');
    }

    nav.classList.toggle('visible');
  }

  $navTrigger.click(navToggle);

};

const mainGallery = () => {
  const $mainGallery = $('.main-gallery');

  if ($mainGallery) {
    $mainGallery.lightGallery();
  }
};

const mainCarousel = () => {
  const $mainCarousel = $('.main-carousel');

  if ($mainCarousel) {
    const carousel = $mainCarousel.lightSlider({
      slideMargin: 7,
      controls: false,
      pager: false,
      onSliderLoad: (el) => {
        const height = [];
        const $items = el.children();

        $items.each((i, e) => {
          height.push(e.offsetHeight);
        });
        const maxHeight = Math.max(...height);

        $('.info-card').css('height', maxHeight);
        el.height(maxHeight + 20);
      },
      responsive: [
        {
          breakpoint: 992,
          settings: {
            item: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            item: 1
          }
        }
      ]
    });

    $('.main-carousel__prev').click(carousel.goToPrevSlide);
    $('.main-carousel__next').click(carousel.goToNextSlide);
  }
};

const toTop = () => {
  const btn = $('.to-top');

  if (!btn.length) return;

  if (window.scrollY < window.innerHeight) btn.addClass('hidden');
  else btn.removeClass('hidden');

  btn.click(() => {
    $('body, html').animate({
      scrollTop: 0
    }, 700);
  });
};

const tooltips = () => {
  if (window.innerWidth < 992) return;

  $('[data-tooltip]').each((i, elem) => {
    const tooltip = document.createElement('SPAN');
    const text = elem.dataset.tooltip;

    tooltip.classList.add('my-tooltip');
    tooltip.textContent = text;
    elem.appendChild(tooltip);

    const shouldWrap = (tooltip.getBoundingClientRect().right + 15) > document.body.clientWidth;

    if (shouldWrap) {
      tooltip.style.width = `${tooltip.clientWidth
        - (tooltip.getBoundingClientRect().right - document.body.clientWidth + 50)
      }px`;
      tooltip.style.whiteSpace = 'normal';
    }
  });
};

function documentReady() {
  const $header = $('.header');

  //
  setTimeout(() => {
    if (!$('html').hasClass('wf-active')) $('body').addClass('visible');
  }, 400);

  // Optimized scroll handler
  const raf = window.requestAnimationFrame;
  let lastScrollTop = window.pageYOffset;

  const handleWindowScroll = () => {
    const currentScrollTop = window.pageYOffset;

    if (lastScrollTop === currentScrollTop) {
      raf(handleWindowScroll);
      return false;
      // scroll up
    } if (lastScrollTop > currentScrollTop) {
      if (window.scrollY < window.innerHeight) $('.to-top').addClass('hidden');

      if (window.innerWidth > 768 && window.scrollY === 0) $header.removeClass('fixed');
      else $header.addClass('fixed');

      // scroll down
    } else if (lastScrollTop < currentScrollTop) {
      if (window.scrollY > window.innerHeight) $('.to-top').removeClass('hidden');
      if (window.innerWidth > 768 && window.scrollY > 0) $header.addClass('fixed');
    }


    lastScrollTop = currentScrollTop;
    return raf(handleWindowScroll);
  };

  handleWindowScroll();


  // Functions
  mainGallery();
  mainCarousel();
  toTop();
  mainNav();
  tooltips();
}


// eslint-disable-next-line no-unused-expressions
!(function checkLoad() {
  if (document.readyState !== 'complete') setTimeout(checkLoad, 10);
  else documentReady(); // eslint-disable-line
}());
